.masters-2025-container {
	.masters-2025-content {
		padding: 20px;

		h2 {
			margin-top: 0;
			margin-bottom: 10px;
		}

		p {
			margin-bottom: 20px;
		}

		.bookings-table {
			margin-top: 20px;
		}
	}
}

// Loading state
.masters-loading {
	padding: 20px;
	text-align: center;
	color: #666;
	font-style: italic;
}

.masters-stats-summary {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 30px;
	border-radius: 8px;
	border: 1px solid #ddd;
	max-width: 1350px;

	.masters-stat-card {
		flex: 1;
		min-width: 150px;
		background-color: #fff;
		border-radius: 8px;
		padding: 20px;
		text-align: center;
		transition: transform 0.2s ease;

		.masters-stat-value {
			font-size: 24px;
			font-weight: 600;
			margin-bottom: 8px;
		}

		.masters-stat-label {
			font-size: 14px;
			font-weight: 500;
		}
	}
}

// Group status cell
.masters-group-status-cell {
	padding: 8px !important;
	text-align: center;
	vertical-align: middle;
}

.masters-group-status {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 6px;
	font-weight: 600;
	padding: 4px 8px;
	border-radius: 4px;

	&.clickable {
		cursor: pointer;
		transition: all 0.2s ease;

		&:hover {
			transform: translateY(-2px);
			box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
		}
	}

	&.status-complete {
		color: #2e7d32; // Green
		background-color: rgba(46, 125, 50, 0.1);

		&.clickable:hover {
			background-color: rgba(46, 125, 50, 0.2);
		}
	}

	&.status-incomplete {
		color: #ed6c02; // Orange
		background-color: rgba(237, 108, 2, 0.1);

		&.clickable:hover {
			background-color: rgba(237, 108, 2, 0.2);
		}
	}

	&.status-warning {
		color: #d32f2f; // Red
		background-color: rgba(211, 47, 47, 0.1);

		&.clickable:hover {
			background-color: rgba(211, 47, 47, 0.2);
		}
	}
}

.masters-group-status-loading {
	color: #666;
	font-style: italic;
	font-size: 14px;
}

// Golf Day Settings
.masters-golf-day-settings {
	margin-bottom: 30px;
	background-color: #f9f9f9;
	border-radius: 8px;
	border: 1px solid #eee;
	padding: 20px;
	max-width: 1350px;

	.masters-golf-day-settings-header {
		margin-bottom: 20px;

		h2 {
			font-size: 20px;
			margin: 0;
			color: #333;
		}
	}

	.masters-golf-day-settings-form {
		display: flex;
		flex-wrap: wrap;
		align-items: flex-end;
		gap: 20px;

		.masters-golf-day-setting {
			display: flex;
			flex-direction: column;
			min-width: 200px;

			label {
				font-size: 14px;
				margin-bottom: 5px;
				color: #555;
				font-weight: 500;
			}

			.ui-input-wrapper {
				margin: 0;
			}
		}

		button {
			margin-bottom: 1px;
		}
	}
}

// Golf Day Participants
.masters-golf-day-participants {
	margin-bottom: 30px;
	max-width: 1350px;

	.masters-golf-day-table {
		margin-bottom: 20px;
		border: 1px solid #eee;
		border-radius: 8px;
		overflow: hidden;

		th {
			background-color: #f2f2f2;
			padding: 12px 15px;
			text-align: left;
			font-weight: 500;
			color: #333;
			white-space: nowrap;
			font-size: 14px;
		}

		td {
			padding: 10px 15px;
			border-bottom: 1px solid #eee;
			vertical-align: middle;

			.ui-input-wrapper {
				margin: 0;
				width: 100px;
			}
		}

		tr:last-child td {
			border-bottom: none;
		}

		tr:hover {
			background-color: #f9f9f9;
		}
	}

	.masters-golf-day-actions {
		display: flex;
		justify-content: flex-end;
		margin-top: 20px;

		button {
			min-width: 150px;
		}
	}
}

.masters-no-data {
	text-align: center;
	padding: 20px;
	color: #888;
	font-style: italic;
}

// Club Hire Tab
.masters-club-hire-container {
	margin-bottom: 30px;
	max-width: 1350px;
}

.masters-club-hire-header {
	margin-bottom: 20px;

	h2 {
		font-size: 20px;
		margin: 0 0 10px;
		color: #333;
	}

	.masters-club-hire-description {
		color: #666;
		font-size: 14px;
		margin-bottom: 15px;
	}
}

.masters-club-hire-date-selector {
	display: flex;
	align-items: center;
	margin-bottom: 20px;
	background-color: #f9f9f9;
	border-radius: 8px;
	border: 1px solid #eee;
	padding: 15px;

	label {
		font-size: 15px;
		font-weight: 500;
		margin-right: 15px;
		color: #333;
	}

	input[type="date"] {
		padding: 8px 12px;
		border: 1px solid #ddd;
		border-radius: 4px;
		font-size: 14px;
		color: #333;
		background-color: #fff;
		min-width: 200px;

		&:focus {
			outline: none;
			border-color: #1976d2;
			box-shadow: 0 0 0 2px rgba(25, 118, 210, 0.2);
		}
	}
}

.masters-club-hire-week-summary {
	margin-bottom: 30px;

	h3 {
		font-size: 18px;
		margin: 0 0 15px;
		color: #333;
	}
}

.masters-club-hire-week-grid {
	display: grid;
	grid-template-columns: repeat(7, 1fr);
	gap: 10px;
	margin-bottom: 20px;

	.masters-club-hire-day-card {
		background: white;
		border-radius: 8px;
		padding: 15px;
		text-align: center;
		cursor: pointer;
		transition: all 0.2s ease;
		border: 2px solid transparent;
		box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);

		&:hover {
			transform: translateY(-2px);
			box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
		}

		&.active {
			border-color: #3498db;
			box-shadow: 0 2px 4px rgba(52, 152, 219, 0.2);
		}

		&.has-requirements {
			background: #f8f9fa;
			border-color: #2ecc71;

			&.active {
				border-color: #3498db;
			}
		}

		.day-label {
			font-size: 14px;
			color: #666;
			margin-bottom: 5px;
		}

		.day-date {
			font-size: 16px;
			color: #333;
			font-weight: 500;
			margin-bottom: 5px;
		}

		.day-count {
			font-size: 18px;
			font-weight: 600;
		}
	}
}

.masters-club-hire-participants {
	margin-bottom: 30px;

	.masters-club-hire-table {
		margin-bottom: 20px;
		border: 1px solid #eee;
		border-radius: 8px;
		overflow: hidden;

		th {
			background-color: #f2f2f2;
			padding: 12px 15px;
			text-align: left;
			font-weight: 500;
			color: #333;
			white-space: nowrap;
			font-size: 14px;
		}

		td {
			padding: 10px 15px;
			border-bottom: 1px solid #eee;
			vertical-align: middle;
		}

		.masters-club-hire-status-cell {
			text-align: center;

			.ui-checkbox {
				margin: 0 auto;

				.ui-checkbox-box {
					margin-right: 0;
				}

				label {
					display: none;
				}
			}
		}

		tr:last-child td {
			border-bottom: none;
		}

		tr:hover {
			background-color: #f9f9f9;
		}
	}
}

.masters-club-hire-status-summary {
	display: flex;
	flex-wrap: wrap;
	gap: 15px;
	margin-bottom: 20px;

	.masters-status-card {
		flex: 1;
		min-width: 200px;
		background-color: #fff;
		border: 1px solid #eee;
		border-radius: 8px;
		padding: 15px;

		.masters-status-header {
			font-size: 16px;
			font-weight: 500;
			color: #333;
			margin-bottom: 10px;
		}

		.masters-status-value {
			font-size: 20px;
			font-weight: 600;
			color: #1976d2;
			margin-bottom: 5px;
		}

		.masters-status-percentage {
			font-size: 14px;
			color: #666;
			margin-bottom: 10px;
		}

		.masters-status-progress {
			height: 8px;
			background-color: #f0f0f0;
			border-radius: 4px;
			overflow: hidden;

			.masters-status-progress-bar {
				height: 100%;
				background-color: #1976d2;
				border-radius: 4px;
				transition: width 0.3s ease, background-color 0.3s ease;

				&.progress-complete {
					background-color: #2e7d32 !important; // Green for completed progress
				}
			}
		}

		&:nth-child(1) {
			.masters-status-header {
				color: #1976d2; // Blue for sourced
			}
			.masters-status-progress-bar {
				background-color: #1976d2;

				&.progress-complete {
					background-color: #2e7d32 !important; // Green for completed progress
				}
			}
		}

		&:nth-child(2) {
			.masters-status-header {
				color: #2e7d32; // Green for picked up
			}
			.masters-status-progress-bar {
				background-color: #2e7d32;

				&.progress-complete {
					background-color: #2e7d32 !important; // Keep green for completed
				}
			}
		}

		&:nth-child(3) {
			.masters-status-header {
				color: #ed6c02; // Orange for returned
			}
			.masters-status-progress-bar {
				background-color: #ed6c02;

				&.progress-complete {
					background-color: #ed6c02 !important; // Keep orange for completed
				}
			}
		}
	}
}

.masters-club-hire-status-indicator {
	width: 80px;
	text-align: center;

	.saving-indicator {
		display: inline-block;
		font-size: 13px;
		font-weight: 500;
		font-style: italic;
		animation: pulse 1.5s infinite;
		padding: 4px 8px;
		background-color: rgba(25, 118, 210, 0.1);
		border-radius: 4px;
	}
}

@keyframes pulse {
	0% {
		opacity: 0.6;
	}
	50% {
		opacity: 1;
	}
	100% {
		opacity: 0.6;
	}
}

// Responsive adjustments
@media (max-width: 768px) {
	.masters-stats-summary {
		flex-direction: column;

		.masters-stat-card {
			width: 100%;
		}
	}

	.masters-golf-day-settings {
		.masters-golf-day-settings-form {
			flex-direction: column;
			align-items: stretch;

			.masters-golf-day-setting {
				width: 100%;
			}

			button {
				width: 100%;
			}
		}
	}

	.masters-golf-day-participants {
		.masters-golf-day-table {
			overflow-x: auto;
			display: block;

			th,
			td {
				padding: 8px;
			}

			td .ui-input-wrapper {
				width: 80px;
			}
		}

		.masters-golf-day-actions {
			button {
				width: 100%;
			}
		}
	}

	.masters-club-hire-date-selector {
		flex-direction: column;
		align-items: flex-start;

		label {
			margin-bottom: 10px;
			margin-right: 0;
		}

		input[type="date"] {
			width: 100%;
		}
	}

	.masters-club-hire-participants {
		.masters-club-hire-table {
			overflow-x: auto;
			display: block;

			th,
			td {
				padding: 8px;
			}
		}
	}

	.masters-club-hire-week-grid {
		flex-direction: column;

		.masters-club-hire-day-card {
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 10px 15px;

			.day-label,
			.day-date,
			.day-count {
				margin: 0;
			}

			.day-count {
				.day-count-details {
					display: inline;
					margin-left: 5px;
				}
			}
		}
	}

	.masters-club-hire-status-summary {
		flex-direction: column;

		.masters-status-card {
			width: 100%;
		}
	}
}

.masters-hotel-pickup-summary {
	background: #f8f9fa;
	border-radius: 8px;
	padding: 20px;
	margin-bottom: 20px;

	h3 {
		margin: 0 0 15px 0;
		font-size: 16px;
		color: #333;
	}

	.masters-hotel-pickup-table {
		margin-bottom: 0;
		border: 1px solid #eee;
		border-radius: 6px;
		overflow: hidden;

		th {
			background-color: #f2f2f2;
			padding: 12px 15px;
			text-align: left;
			font-weight: 500;
			color: #333;
			white-space: nowrap;
			font-size: 14px;
		}

		td {
			padding: 10px 15px;
			border-bottom: 1px solid #eee;
			vertical-align: middle;
			color: #666;
			font-size: 14px;
		}

		tr:last-child td {
			border-bottom: none;
		}

		tr:hover {
			background-color: #f9f9f9;
		}
	}

	.masters-hotel-pickup-loading {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 30px;
		color: #666;

		.loading-spinner {
			width: 30px;
			height: 30px;
			border: 3px solid #f3f3f3;
			border-top: 3px solid #3498db;
			border-radius: 50%;
			animation: spin 1s linear infinite;
			margin-bottom: 10px;
		}

		span {
			font-size: 14px;
		}
	}

	.masters-hotel-pickup-empty {
		text-align: center;
		padding: 30px;
		color: #666;
		font-size: 14px;
		background: white;
		border-radius: 6px;
		box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
